import * as Types from './graphql';

import * as Operations from './graphql';
import { NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient } from '../../lib/apollo-client/apollo-client';
export async function getServerPageBasket
    (options: Omit<Apollo.QueryOptions<Types.BasketPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BasketPageQuery>({ ...options, query: Operations.BasketPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBasket = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BasketPageQuery, Types.BasketPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.BasketPageDocument, options);
};
export type PageBasketComp = React.FC<{data?: Types.BasketPageQuery, error?: Apollo.ApolloError}>;
export const withPageBasket = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BasketPageQuery, Types.BasketPageQueryVariables>) => (WrappedComponent:PageBasketComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.BasketPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBasket = {
      getServerPage: getServerPageBasket,
      withPage: withPageBasket,
      usePage: useBasket,
    }
export async function getServerPageBlogCategories
    (options: Omit<Apollo.QueryOptions<Types.BlogCategoriesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BlogCategoriesQuery>({ ...options, query: Operations.BlogCategoriesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBlogCategories = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogCategoriesQuery, Types.BlogCategoriesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.BlogCategoriesDocument, options);
};
export type PageBlogCategoriesComp = React.FC<{data?: Types.BlogCategoriesQuery, error?: Apollo.ApolloError}>;
export const withPageBlogCategories = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogCategoriesQuery, Types.BlogCategoriesQueryVariables>) => (WrappedComponent:PageBlogCategoriesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.BlogCategoriesDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBlogCategories = {
      getServerPage: getServerPageBlogCategories,
      withPage: withPageBlogCategories,
      usePage: useBlogCategories,
    }
export async function getServerPageBlogOverviewHeader
    (options: Omit<Apollo.QueryOptions<Types.BlogOverviewHeaderQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BlogOverviewHeaderQuery>({ ...options, query: Operations.BlogOverviewHeaderDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBlogOverviewHeader = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogOverviewHeaderQuery, Types.BlogOverviewHeaderQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.BlogOverviewHeaderDocument, options);
};
export type PageBlogOverviewHeaderComp = React.FC<{data?: Types.BlogOverviewHeaderQuery, error?: Apollo.ApolloError}>;
export const withPageBlogOverviewHeader = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogOverviewHeaderQuery, Types.BlogOverviewHeaderQueryVariables>) => (WrappedComponent:PageBlogOverviewHeaderComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.BlogOverviewHeaderDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBlogOverviewHeader = {
      getServerPage: getServerPageBlogOverviewHeader,
      withPage: withPageBlogOverviewHeader,
      usePage: useBlogOverviewHeader,
    }
export async function getServerPageBlogOverviewNewPosts
    (options: Omit<Apollo.QueryOptions<Types.BlogOverviewNewPostsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BlogOverviewNewPostsQuery>({ ...options, query: Operations.BlogOverviewNewPostsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBlogOverviewNewPosts = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogOverviewNewPostsQuery, Types.BlogOverviewNewPostsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.BlogOverviewNewPostsDocument, options);
};
export type PageBlogOverviewNewPostsComp = React.FC<{data?: Types.BlogOverviewNewPostsQuery, error?: Apollo.ApolloError}>;
export const withPageBlogOverviewNewPosts = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogOverviewNewPostsQuery, Types.BlogOverviewNewPostsQueryVariables>) => (WrappedComponent:PageBlogOverviewNewPostsComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.BlogOverviewNewPostsDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBlogOverviewNewPosts = {
      getServerPage: getServerPageBlogOverviewNewPosts,
      withPage: withPageBlogOverviewNewPosts,
      usePage: useBlogOverviewNewPosts,
    }
export async function getServerPageBlogOverview
    (options: Omit<Apollo.QueryOptions<Types.BlogOverviewQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BlogOverviewQuery>({ ...options, query: Operations.BlogOverviewDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBlogOverview = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogOverviewQuery, Types.BlogOverviewQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.BlogOverviewDocument, options);
};
export type PageBlogOverviewComp = React.FC<{data?: Types.BlogOverviewQuery, error?: Apollo.ApolloError}>;
export const withPageBlogOverview = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogOverviewQuery, Types.BlogOverviewQueryVariables>) => (WrappedComponent:PageBlogOverviewComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.BlogOverviewDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBlogOverview = {
      getServerPage: getServerPageBlogOverview,
      withPage: withPageBlogOverview,
      usePage: useBlogOverview,
    }
export async function getServerPageBlog
    (options: Omit<Apollo.QueryOptions<Types.BlogPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BlogPageQuery>({ ...options, query: Operations.BlogPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBlog = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogPageQuery, Types.BlogPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.BlogPageDocument, options);
};
export type PageBlogComp = React.FC<{data?: Types.BlogPageQuery, error?: Apollo.ApolloError}>;
export const withPageBlog = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogPageQuery, Types.BlogPageQueryVariables>) => (WrappedComponent:PageBlogComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.BlogPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBlog = {
      getServerPage: getServerPageBlog,
      withPage: withPageBlog,
      usePage: useBlog,
    }
export async function getServerPageConfigurator
    (options: Omit<Apollo.QueryOptions<Types.ConfiguratorPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ConfiguratorPageQuery>({ ...options, query: Operations.ConfiguratorPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useConfigurator = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ConfiguratorPageQuery, Types.ConfiguratorPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ConfiguratorPageDocument, options);
};
export type PageConfiguratorComp = React.FC<{data?: Types.ConfiguratorPageQuery, error?: Apollo.ApolloError}>;
export const withPageConfigurator = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ConfiguratorPageQuery, Types.ConfiguratorPageQueryVariables>) => (WrappedComponent:PageConfiguratorComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ConfiguratorPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrConfigurator = {
      getServerPage: getServerPageConfigurator,
      withPage: withPageConfigurator,
      usePage: useConfigurator,
    }
export async function getServerPageError
    (options: Omit<Apollo.QueryOptions<Types.ErrorPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ErrorPageQuery>({ ...options, query: Operations.ErrorPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useError = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ErrorPageQuery, Types.ErrorPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ErrorPageDocument, options);
};
export type PageErrorComp = React.FC<{data?: Types.ErrorPageQuery, error?: Apollo.ApolloError}>;
export const withPageError = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ErrorPageQuery, Types.ErrorPageQueryVariables>) => (WrappedComponent:PageErrorComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ErrorPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrError = {
      getServerPage: getServerPageError,
      withPage: withPageError,
      usePage: useError,
    }
export async function getServerPageHome
    (options: Omit<Apollo.QueryOptions<Types.HomepageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.HomepageQuery>({ ...options, query: Operations.HomepageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useHome = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.HomepageQuery, Types.HomepageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.HomepageDocument, options);
};
export type PageHomeComp = React.FC<{data?: Types.HomepageQuery, error?: Apollo.ApolloError}>;
export const withPageHome = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.HomepageQuery, Types.HomepageQueryVariables>) => (WrappedComponent:PageHomeComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.HomepageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrHome = {
      getServerPage: getServerPageHome,
      withPage: withPageHome,
      usePage: useHome,
    }
export async function getServerPageGetJobOverview
    (options: Omit<Apollo.QueryOptions<Types.GetJobOverviewQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetJobOverviewQuery>({ ...options, query: Operations.GetJobOverviewDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetJobOverview = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetJobOverviewQuery, Types.GetJobOverviewQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetJobOverviewDocument, options);
};
export type PageGetJobOverviewComp = React.FC<{data?: Types.GetJobOverviewQuery, error?: Apollo.ApolloError}>;
export const withPageGetJobOverview = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetJobOverviewQuery, Types.GetJobOverviewQueryVariables>) => (WrappedComponent:PageGetJobOverviewComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GetJobOverviewDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetJobOverview = {
      getServerPage: getServerPageGetJobOverview,
      withPage: withPageGetJobOverview,
      usePage: useGetJobOverview,
    }
export async function getServerPageJob
    (options: Omit<Apollo.QueryOptions<Types.JobQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.JobQuery>({ ...options, query: Operations.JobDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useJob = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.JobQuery, Types.JobQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.JobDocument, options);
};
export type PageJobComp = React.FC<{data?: Types.JobQuery, error?: Apollo.ApolloError}>;
export const withPageJob = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.JobQuery, Types.JobQueryVariables>) => (WrappedComponent:PageJobComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.JobDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrJob = {
      getServerPage: getServerPageJob,
      withPage: withPageJob,
      usePage: useJob,
    }
export async function getServerPageGetLogin
    (options: Omit<Apollo.QueryOptions<Types.GetLoginPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetLoginPageQuery>({ ...options, query: Operations.GetLoginPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetLogin = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetLoginPageQuery, Types.GetLoginPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetLoginPageDocument, options);
};
export type PageGetLoginComp = React.FC<{data?: Types.GetLoginPageQuery, error?: Apollo.ApolloError}>;
export const withPageGetLogin = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetLoginPageQuery, Types.GetLoginPageQueryVariables>) => (WrappedComponent:PageGetLoginComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GetLoginPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetLogin = {
      getServerPage: getServerPageGetLogin,
      withPage: withPageGetLogin,
      usePage: useGetLogin,
    }
export async function getServerPageGetNavigation
    (options: Omit<Apollo.QueryOptions<Types.GetNavigationQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetNavigationQuery>({ ...options, query: Operations.GetNavigationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetNavigation = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetNavigationQuery, Types.GetNavigationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetNavigationDocument, options);
};
export type PageGetNavigationComp = React.FC<{data?: Types.GetNavigationQuery, error?: Apollo.ApolloError}>;
export const withPageGetNavigation = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetNavigationQuery, Types.GetNavigationQueryVariables>) => (WrappedComponent:PageGetNavigationComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GetNavigationDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetNavigation = {
      getServerPage: getServerPageGetNavigation,
      withPage: withPageGetNavigation,
      usePage: useGetNavigation,
    }
export async function getServerPageProduct
    (options: Omit<Apollo.QueryOptions<Types.ProductPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProductPageQuery>({ ...options, query: Operations.ProductPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProduct = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductPageQuery, Types.ProductPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ProductPageDocument, options);
};
export type PageProductComp = React.FC<{data?: Types.ProductPageQuery, error?: Apollo.ApolloError}>;
export const withPageProduct = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductPageQuery, Types.ProductPageQueryVariables>) => (WrappedComponent:PageProductComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ProductPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrProduct = {
      getServerPage: getServerPageProduct,
      withPage: withPageProduct,
      usePage: useProduct,
    }
export async function getServerPageSearch
    (options: Omit<Apollo.QueryOptions<Types.SearchPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SearchPageQuery>({ ...options, query: Operations.SearchPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSearch = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SearchPageQuery, Types.SearchPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.SearchPageDocument, options);
};
export type PageSearchComp = React.FC<{data?: Types.SearchPageQuery, error?: Apollo.ApolloError}>;
export const withPageSearch = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SearchPageQuery, Types.SearchPageQueryVariables>) => (WrappedComponent:PageSearchComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.SearchPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrSearch = {
      getServerPage: getServerPageSearch,
      withPage: withPageSearch,
      usePage: useSearch,
    }
export async function getServerPageSitemap
    (options: Omit<Apollo.QueryOptions<Types.SitemapPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SitemapPageQuery>({ ...options, query: Operations.SitemapPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSitemap = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SitemapPageQuery, Types.SitemapPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.SitemapPageDocument, options);
};
export type PageSitemapComp = React.FC<{data?: Types.SitemapPageQuery, error?: Apollo.ApolloError}>;
export const withPageSitemap = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SitemapPageQuery, Types.SitemapPageQueryVariables>) => (WrappedComponent:PageSitemapComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.SitemapPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrSitemap = {
      getServerPage: getServerPageSitemap,
      withPage: withPageSitemap,
      usePage: useSitemap,
    }
export async function getServerPageStandard
    (options: Omit<Apollo.QueryOptions<Types.StandardPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.StandardPageQuery>({ ...options, query: Operations.StandardPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useStandard = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.StandardPageQuery, Types.StandardPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.StandardPageDocument, options);
};
export type PageStandardComp = React.FC<{data?: Types.StandardPageQuery, error?: Apollo.ApolloError}>;
export const withPageStandard = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.StandardPageQuery, Types.StandardPageQueryVariables>) => (WrappedComponent:PageStandardComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.StandardPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrStandard = {
      getServerPage: getServerPageStandard,
      withPage: withPageStandard,
      usePage: useStandard,
    }
export async function getServerPageTenantMap
    (options: Omit<Apollo.QueryOptions<Types.TenantMapPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.TenantMapPageQuery>({ ...options, query: Operations.TenantMapPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTenantMap = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantMapPageQuery, Types.TenantMapPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.TenantMapPageDocument, options);
};
export type PageTenantMapComp = React.FC<{data?: Types.TenantMapPageQuery, error?: Apollo.ApolloError}>;
export const withPageTenantMap = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantMapPageQuery, Types.TenantMapPageQueryVariables>) => (WrappedComponent:PageTenantMapComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.TenantMapPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrTenantMap = {
      getServerPage: getServerPageTenantMap,
      withPage: withPageTenantMap,
      usePage: useTenantMap,
    }
export async function getServerPageTenant
    (options: Omit<Apollo.QueryOptions<Types.TenantPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.TenantPageQuery>({ ...options, query: Operations.TenantPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTenant = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantPageQuery, Types.TenantPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.TenantPageDocument, options);
};
export type PageTenantComp = React.FC<{data?: Types.TenantPageQuery, error?: Apollo.ApolloError}>;
export const withPageTenant = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantPageQuery, Types.TenantPageQueryVariables>) => (WrappedComponent:PageTenantComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.TenantPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrTenant = {
      getServerPage: getServerPageTenant,
      withPage: withPageTenant,
      usePage: useTenant,
    }
export async function getServerPageSearchById
    (options: Omit<Apollo.QueryOptions<Types.SearchPageByIdQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SearchPageByIdQuery>({ ...options, query: Operations.SearchPageByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSearchById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SearchPageByIdQuery, Types.SearchPageByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.SearchPageByIdDocument, options);
};
export type PageSearchByIdComp = React.FC<{data?: Types.SearchPageByIdQuery, error?: Apollo.ApolloError}>;
export const withPageSearchById = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SearchPageByIdQuery, Types.SearchPageByIdQueryVariables>) => (WrappedComponent:PageSearchByIdComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.SearchPageByIdDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrSearchById = {
      getServerPage: getServerPageSearchById,
      withPage: withPageSearchById,
      usePage: useSearchById,
    }
















export async function getServerPageAddressAutocomplete
    (options: Omit<Apollo.QueryOptions<Types.AddressAutocompleteQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.AddressAutocompleteQuery>({ ...options, query: Operations.AddressAutocompleteDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useAddressAutocomplete = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.AddressAutocompleteQuery, Types.AddressAutocompleteQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.AddressAutocompleteDocument, options);
};
export type PageAddressAutocompleteComp = React.FC<{data?: Types.AddressAutocompleteQuery, error?: Apollo.ApolloError}>;
export const withPageAddressAutocomplete = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.AddressAutocompleteQuery, Types.AddressAutocompleteQueryVariables>) => (WrappedComponent:PageAddressAutocompleteComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.AddressAutocompleteDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrAddressAutocomplete = {
      getServerPage: getServerPageAddressAutocomplete,
      withPage: withPageAddressAutocomplete,
      usePage: useAddressAutocomplete,
    }
export async function getServerPageAuthProcessContent
    (options: Omit<Apollo.QueryOptions<Types.AuthProcessContentQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.AuthProcessContentQuery>({ ...options, query: Operations.AuthProcessContentDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useAuthProcessContent = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.AuthProcessContentQuery, Types.AuthProcessContentQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.AuthProcessContentDocument, options);
};
export type PageAuthProcessContentComp = React.FC<{data?: Types.AuthProcessContentQuery, error?: Apollo.ApolloError}>;
export const withPageAuthProcessContent = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.AuthProcessContentQuery, Types.AuthProcessContentQueryVariables>) => (WrappedComponent:PageAuthProcessContentComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.AuthProcessContentDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrAuthProcessContent = {
      getServerPage: getServerPageAuthProcessContent,
      withPage: withPageAuthProcessContent,
      usePage: useAuthProcessContent,
    }
export async function getServerPageBasketData
    (options: Omit<Apollo.QueryOptions<Types.BasketDataQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BasketDataQuery>({ ...options, query: Operations.BasketDataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBasketData = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BasketDataQuery, Types.BasketDataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.BasketDataDocument, options);
};
export type PageBasketDataComp = React.FC<{data?: Types.BasketDataQuery, error?: Apollo.ApolloError}>;
export const withPageBasketData = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BasketDataQuery, Types.BasketDataQueryVariables>) => (WrappedComponent:PageBasketDataComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.BasketDataDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBasketData = {
      getServerPage: getServerPageBasketData,
      withPage: withPageBasketData,
      usePage: useBasketData,
    }
export async function getServerPageBookingsAsCsv
    (options: Omit<Apollo.QueryOptions<Types.BookingsAsCsvQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BookingsAsCsvQuery>({ ...options, query: Operations.BookingsAsCsvDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBookingsAsCsv = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookingsAsCsvQuery, Types.BookingsAsCsvQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.BookingsAsCsvDocument, options);
};
export type PageBookingsAsCsvComp = React.FC<{data?: Types.BookingsAsCsvQuery, error?: Apollo.ApolloError}>;
export const withPageBookingsAsCsv = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookingsAsCsvQuery, Types.BookingsAsCsvQueryVariables>) => (WrappedComponent:PageBookingsAsCsvComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.BookingsAsCsvDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBookingsAsCsv = {
      getServerPage: getServerPageBookingsAsCsv,
      withPage: withPageBookingsAsCsv,
      usePage: useBookingsAsCsv,
    }
export async function getServerPageCart
    (options: Omit<Apollo.QueryOptions<Types.CartQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CartQuery>({ ...options, query: Operations.CartDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCart = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CartQuery, Types.CartQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CartDocument, options);
};
export type PageCartComp = React.FC<{data?: Types.CartQuery, error?: Apollo.ApolloError}>;
export const withPageCart = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CartQuery, Types.CartQueryVariables>) => (WrappedComponent:PageCartComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.CartDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCart = {
      getServerPage: getServerPageCart,
      withPage: withPageCart,
      usePage: useCart,
    }
export async function getServerPageCatalogSearch
    (options: Omit<Apollo.QueryOptions<Types.CatalogSearchQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CatalogSearchQuery>({ ...options, query: Operations.CatalogSearchDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCatalogSearch = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CatalogSearchQuery, Types.CatalogSearchQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CatalogSearchDocument, options);
};
export type PageCatalogSearchComp = React.FC<{data?: Types.CatalogSearchQuery, error?: Apollo.ApolloError}>;
export const withPageCatalogSearch = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CatalogSearchQuery, Types.CatalogSearchQueryVariables>) => (WrappedComponent:PageCatalogSearchComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.CatalogSearchDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCatalogSearch = {
      getServerPage: getServerPageCatalogSearch,
      withPage: withPageCatalogSearch,
      usePage: useCatalogSearch,
    }
export async function getServerPageCategoryByText
    (options: Omit<Apollo.QueryOptions<Types.CategoryByTextQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategoryByTextQuery>({ ...options, query: Operations.CategoryByTextDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCategoryByText = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategoryByTextQuery, Types.CategoryByTextQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CategoryByTextDocument, options);
};
export type PageCategoryByTextComp = React.FC<{data?: Types.CategoryByTextQuery, error?: Apollo.ApolloError}>;
export const withPageCategoryByText = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategoryByTextQuery, Types.CategoryByTextQueryVariables>) => (WrappedComponent:PageCategoryByTextComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.CategoryByTextDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCategoryByText = {
      getServerPage: getServerPageCategoryByText,
      withPage: withPageCategoryByText,
      usePage: useCategoryByText,
    }
export async function getServerPageCategoryByUriComponent
    (options: Omit<Apollo.QueryOptions<Types.CategoryByUriComponentQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategoryByUriComponentQuery>({ ...options, query: Operations.CategoryByUriComponentDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCategoryByUriComponent = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategoryByUriComponentQuery, Types.CategoryByUriComponentQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CategoryByUriComponentDocument, options);
};
export type PageCategoryByUriComponentComp = React.FC<{data?: Types.CategoryByUriComponentQuery, error?: Apollo.ApolloError}>;
export const withPageCategoryByUriComponent = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategoryByUriComponentQuery, Types.CategoryByUriComponentQueryVariables>) => (WrappedComponent:PageCategoryByUriComponentComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.CategoryByUriComponentDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCategoryByUriComponent = {
      getServerPage: getServerPageCategoryByUriComponent,
      withPage: withPageCategoryByUriComponent,
      usePage: useCategoryByUriComponent,
    }
export async function getServerPageCategorySitemapVirtual
    (options: Omit<Apollo.QueryOptions<Types.CategorySitemapVirtualQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategorySitemapVirtualQuery>({ ...options, query: Operations.CategorySitemapVirtualDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCategorySitemapVirtual = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategorySitemapVirtualQuery, Types.CategorySitemapVirtualQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CategorySitemapVirtualDocument, options);
};
export type PageCategorySitemapVirtualComp = React.FC<{data?: Types.CategorySitemapVirtualQuery, error?: Apollo.ApolloError}>;
export const withPageCategorySitemapVirtual = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategorySitemapVirtualQuery, Types.CategorySitemapVirtualQueryVariables>) => (WrappedComponent:PageCategorySitemapVirtualComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.CategorySitemapVirtualDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCategorySitemapVirtual = {
      getServerPage: getServerPageCategorySitemapVirtual,
      withPage: withPageCategorySitemapVirtual,
      usePage: useCategorySitemapVirtual,
    }
export async function getServerPageCategorySitemap
    (options: Omit<Apollo.QueryOptions<Types.CategorySitemapQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategorySitemapQuery>({ ...options, query: Operations.CategorySitemapDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCategorySitemap = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategorySitemapQuery, Types.CategorySitemapQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CategorySitemapDocument, options);
};
export type PageCategorySitemapComp = React.FC<{data?: Types.CategorySitemapQuery, error?: Apollo.ApolloError}>;
export const withPageCategorySitemap = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategorySitemapQuery, Types.CategorySitemapQueryVariables>) => (WrappedComponent:PageCategorySitemapComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.CategorySitemapDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCategorySitemap = {
      getServerPage: getServerPageCategorySitemap,
      withPage: withPageCategorySitemap,
      usePage: useCategorySitemap,
    }
export async function getServerPageCountries
    (options: Omit<Apollo.QueryOptions<Types.CountriesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CountriesQuery>({ ...options, query: Operations.CountriesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCountries = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CountriesDocument, options);
};
export type PageCountriesComp = React.FC<{data?: Types.CountriesQuery, error?: Apollo.ApolloError}>;
export const withPageCountries = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) => (WrappedComponent:PageCountriesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.CountriesDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCountries = {
      getServerPage: getServerPageCountries,
      withPage: withPageCountries,
      usePage: useCountries,
    }
export async function getServerPageDatoDocumentRelations
    (options: Omit<Apollo.QueryOptions<Types.DatoDocumentRelationsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.DatoDocumentRelationsQuery>({ ...options, query: Operations.DatoDocumentRelationsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useDatoDocumentRelations = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.DatoDocumentRelationsQuery, Types.DatoDocumentRelationsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.DatoDocumentRelationsDocument, options);
};
export type PageDatoDocumentRelationsComp = React.FC<{data?: Types.DatoDocumentRelationsQuery, error?: Apollo.ApolloError}>;
export const withPageDatoDocumentRelations = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.DatoDocumentRelationsQuery, Types.DatoDocumentRelationsQueryVariables>) => (WrappedComponent:PageDatoDocumentRelationsComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.DatoDocumentRelationsDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrDatoDocumentRelations = {
      getServerPage: getServerPageDatoDocumentRelations,
      withPage: withPageDatoDocumentRelations,
      usePage: useDatoDocumentRelations,
    }
export async function getServerPageGeolocation
    (options: Omit<Apollo.QueryOptions<Types.GeolocationQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GeolocationQuery>({ ...options, query: Operations.GeolocationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGeolocation = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GeolocationQuery, Types.GeolocationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GeolocationDocument, options);
};
export type PageGeolocationComp = React.FC<{data?: Types.GeolocationQuery, error?: Apollo.ApolloError}>;
export const withPageGeolocation = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GeolocationQuery, Types.GeolocationQueryVariables>) => (WrappedComponent:PageGeolocationComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GeolocationDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGeolocation = {
      getServerPage: getServerPageGeolocation,
      withPage: withPageGeolocation,
      usePage: useGeolocation,
    }
export async function getServerPageGetAddresses
    (options: Omit<Apollo.QueryOptions<Types.GetAddressesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetAddressesQuery>({ ...options, query: Operations.GetAddressesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAddresses = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetAddressesQuery, Types.GetAddressesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAddressesDocument, options);
};
export type PageGetAddressesComp = React.FC<{data?: Types.GetAddressesQuery, error?: Apollo.ApolloError}>;
export const withPageGetAddresses = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetAddressesQuery, Types.GetAddressesQueryVariables>) => (WrappedComponent:PageGetAddressesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GetAddressesDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetAddresses = {
      getServerPage: getServerPageGetAddresses,
      withPage: withPageGetAddresses,
      usePage: useGetAddresses,
    }
export async function getServerPageHasUserBeenMigrated
    (options: Omit<Apollo.QueryOptions<Types.HasUserBeenMigratedQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.HasUserBeenMigratedQuery>({ ...options, query: Operations.HasUserBeenMigratedDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useHasUserBeenMigrated = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.HasUserBeenMigratedQuery, Types.HasUserBeenMigratedQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.HasUserBeenMigratedDocument, options);
};
export type PageHasUserBeenMigratedComp = React.FC<{data?: Types.HasUserBeenMigratedQuery, error?: Apollo.ApolloError}>;
export const withPageHasUserBeenMigrated = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.HasUserBeenMigratedQuery, Types.HasUserBeenMigratedQueryVariables>) => (WrappedComponent:PageHasUserBeenMigratedComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.HasUserBeenMigratedDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrHasUserBeenMigrated = {
      getServerPage: getServerPageHasUserBeenMigrated,
      withPage: withPageHasUserBeenMigrated,
      usePage: useHasUserBeenMigrated,
    }
export async function getServerPageLocationAutocomplete
    (options: Omit<Apollo.QueryOptions<Types.LocationAutocompleteQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.LocationAutocompleteQuery>({ ...options, query: Operations.LocationAutocompleteDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useLocationAutocomplete = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.LocationAutocompleteQuery, Types.LocationAutocompleteQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.LocationAutocompleteDocument, options);
};
export type PageLocationAutocompleteComp = React.FC<{data?: Types.LocationAutocompleteQuery, error?: Apollo.ApolloError}>;
export const withPageLocationAutocomplete = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.LocationAutocompleteQuery, Types.LocationAutocompleteQueryVariables>) => (WrappedComponent:PageLocationAutocompleteComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.LocationAutocompleteDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrLocationAutocomplete = {
      getServerPage: getServerPageLocationAutocomplete,
      withPage: withPageLocationAutocomplete,
      usePage: useLocationAutocomplete,
    }
export async function getServerPageLocationByPlaceId
    (options: Omit<Apollo.QueryOptions<Types.LocationByPlaceIdQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.LocationByPlaceIdQuery>({ ...options, query: Operations.LocationByPlaceIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useLocationByPlaceId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.LocationByPlaceIdQuery, Types.LocationByPlaceIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.LocationByPlaceIdDocument, options);
};
export type PageLocationByPlaceIdComp = React.FC<{data?: Types.LocationByPlaceIdQuery, error?: Apollo.ApolloError}>;
export const withPageLocationByPlaceId = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.LocationByPlaceIdQuery, Types.LocationByPlaceIdQueryVariables>) => (WrappedComponent:PageLocationByPlaceIdComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.LocationByPlaceIdDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrLocationByPlaceId = {
      getServerPage: getServerPageLocationByPlaceId,
      withPage: withPageLocationByPlaceId,
      usePage: useLocationByPlaceId,
    }
export async function getServerPageLocationByUriComponent
    (options: Omit<Apollo.QueryOptions<Types.LocationByUriComponentQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.LocationByUriComponentQuery>({ ...options, query: Operations.LocationByUriComponentDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useLocationByUriComponent = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.LocationByUriComponentQuery, Types.LocationByUriComponentQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.LocationByUriComponentDocument, options);
};
export type PageLocationByUriComponentComp = React.FC<{data?: Types.LocationByUriComponentQuery, error?: Apollo.ApolloError}>;
export const withPageLocationByUriComponent = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.LocationByUriComponentQuery, Types.LocationByUriComponentQueryVariables>) => (WrappedComponent:PageLocationByUriComponentComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.LocationByUriComponentDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrLocationByUriComponent = {
      getServerPage: getServerPageLocationByUriComponent,
      withPage: withPageLocationByUriComponent,
      usePage: useLocationByUriComponent,
    }
export async function getServerPageMe
    (options: Omit<Apollo.QueryOptions<Types.MeQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.MeQuery>({ ...options, query: Operations.MeDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useMe = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.MeQuery, Types.MeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.MeDocument, options);
};
export type PageMeComp = React.FC<{data?: Types.MeQuery, error?: Apollo.ApolloError}>;
export const withPageMe = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.MeQuery, Types.MeQueryVariables>) => (WrappedComponent:PageMeComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.MeDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrMe = {
      getServerPage: getServerPageMe,
      withPage: withPageMe,
      usePage: useMe,
    }
export async function getServerPageCheckVatId
    (options: Omit<Apollo.QueryOptions<Types.CheckVatIdQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CheckVatIdQuery>({ ...options, query: Operations.CheckVatIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCheckVatId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CheckVatIdQuery, Types.CheckVatIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CheckVatIdDocument, options);
};
export type PageCheckVatIdComp = React.FC<{data?: Types.CheckVatIdQuery, error?: Apollo.ApolloError}>;
export const withPageCheckVatId = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CheckVatIdQuery, Types.CheckVatIdQueryVariables>) => (WrappedComponent:PageCheckVatIdComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.CheckVatIdDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCheckVatId = {
      getServerPage: getServerPageCheckVatId,
      withPage: withPageCheckVatId,
      usePage: useCheckVatId,
    }
export async function getServerPageGetorganizationMembers
    (options: Omit<Apollo.QueryOptions<Types.GetorganizationMembersQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetorganizationMembersQuery>({ ...options, query: Operations.GetorganizationMembersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetorganizationMembers = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetorganizationMembersQuery, Types.GetorganizationMembersQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetorganizationMembersDocument, options);
};
export type PageGetorganizationMembersComp = React.FC<{data?: Types.GetorganizationMembersQuery, error?: Apollo.ApolloError}>;
export const withPageGetorganizationMembers = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetorganizationMembersQuery, Types.GetorganizationMembersQueryVariables>) => (WrappedComponent:PageGetorganizationMembersComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GetorganizationMembersDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetorganizationMembers = {
      getServerPage: getServerPageGetorganizationMembers,
      withPage: withPageGetorganizationMembers,
      usePage: useGetorganizationMembers,
    }
export async function getServerPageBooking
    (options: Omit<Apollo.QueryOptions<Types.BookingPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BookingPageQuery>({ ...options, query: Operations.BookingPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBooking = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookingPageQuery, Types.BookingPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.BookingPageDocument, options);
};
export type PageBookingComp = React.FC<{data?: Types.BookingPageQuery, error?: Apollo.ApolloError}>;
export const withPageBooking = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookingPageQuery, Types.BookingPageQueryVariables>) => (WrappedComponent:PageBookingComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.BookingPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBooking = {
      getServerPage: getServerPageBooking,
      withPage: withPageBooking,
      usePage: useBooking,
    }
export async function getServerPageBookingsList
    (options: Omit<Apollo.QueryOptions<Types.BookingsListPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BookingsListPageQuery>({ ...options, query: Operations.BookingsListPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBookingsList = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookingsListPageQuery, Types.BookingsListPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.BookingsListPageDocument, options);
};
export type PageBookingsListComp = React.FC<{data?: Types.BookingsListPageQuery, error?: Apollo.ApolloError}>;
export const withPageBookingsList = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookingsListPageQuery, Types.BookingsListPageQueryVariables>) => (WrappedComponent:PageBookingsListComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.BookingsListPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBookingsList = {
      getServerPage: getServerPageBookingsList,
      withPage: withPageBookingsList,
      usePage: useBookingsList,
    }
export async function getServerPageConfiguratorData
    (options: Omit<Apollo.QueryOptions<Types.ConfiguratorDataQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ConfiguratorDataQuery>({ ...options, query: Operations.ConfiguratorDataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useConfiguratorData = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ConfiguratorDataQuery, Types.ConfiguratorDataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ConfiguratorDataDocument, options);
};
export type PageConfiguratorDataComp = React.FC<{data?: Types.ConfiguratorDataQuery, error?: Apollo.ApolloError}>;
export const withPageConfiguratorData = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ConfiguratorDataQuery, Types.ConfiguratorDataQueryVariables>) => (WrappedComponent:PageConfiguratorDataComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ConfiguratorDataDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrConfiguratorData = {
      getServerPage: getServerPageConfiguratorData,
      withPage: withPageConfiguratorData,
      usePage: useConfiguratorData,
    }
export async function getServerPageContent
    (options: Omit<Apollo.QueryOptions<Types.ContentPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ContentPageQuery>({ ...options, query: Operations.ContentPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useContent = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ContentPageQuery, Types.ContentPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ContentPageDocument, options);
};
export type PageContentComp = React.FC<{data?: Types.ContentPageQuery, error?: Apollo.ApolloError}>;
export const withPageContent = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ContentPageQuery, Types.ContentPageQueryVariables>) => (WrappedComponent:PageContentComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ContentPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrContent = {
      getServerPage: getServerPageContent,
      withPage: withPageContent,
      usePage: useContent,
    }
export async function getServerPageSitemapProductTypes
    (options: Omit<Apollo.QueryOptions<Types.SitemapProductTypesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SitemapProductTypesQuery>({ ...options, query: Operations.SitemapProductTypesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSitemapProductTypes = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SitemapProductTypesQuery, Types.SitemapProductTypesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.SitemapProductTypesDocument, options);
};
export type PageSitemapProductTypesComp = React.FC<{data?: Types.SitemapProductTypesQuery, error?: Apollo.ApolloError}>;
export const withPageSitemapProductTypes = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SitemapProductTypesQuery, Types.SitemapProductTypesQueryVariables>) => (WrappedComponent:PageSitemapProductTypesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.SitemapProductTypesDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrSitemapProductTypes = {
      getServerPage: getServerPageSitemapProductTypes,
      withPage: withPageSitemapProductTypes,
      usePage: useSitemapProductTypes,
    }
export async function getServerPageSearchData
    (options: Omit<Apollo.QueryOptions<Types.SearchPageDataQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SearchPageDataQuery>({ ...options, query: Operations.SearchPageDataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSearchData = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SearchPageDataQuery, Types.SearchPageDataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.SearchPageDataDocument, options);
};
export type PageSearchDataComp = React.FC<{data?: Types.SearchPageDataQuery, error?: Apollo.ApolloError}>;
export const withPageSearchData = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SearchPageDataQuery, Types.SearchPageDataQueryVariables>) => (WrappedComponent:PageSearchDataComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.SearchPageDataDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrSearchData = {
      getServerPage: getServerPageSearchData,
      withPage: withPageSearchData,
      usePage: useSearchData,
    }
export async function getServerPageStaticSearch
    (options: Omit<Apollo.QueryOptions<Types.StaticSearchPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.StaticSearchPageQuery>({ ...options, query: Operations.StaticSearchPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useStaticSearch = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.StaticSearchPageQuery, Types.StaticSearchPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.StaticSearchPageDocument, options);
};
export type PageStaticSearchComp = React.FC<{data?: Types.StaticSearchPageQuery, error?: Apollo.ApolloError}>;
export const withPageStaticSearch = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.StaticSearchPageQuery, Types.StaticSearchPageQueryVariables>) => (WrappedComponent:PageStaticSearchComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.StaticSearchPageDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrStaticSearch = {
      getServerPage: getServerPageStaticSearch,
      withPage: withPageStaticSearch,
      usePage: useStaticSearch,
    }
export async function getServerPagePlace
    (options: Omit<Apollo.QueryOptions<Types.PlaceQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.PlaceQuery>({ ...options, query: Operations.PlaceDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePlace = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.PlaceQuery, Types.PlaceQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.PlaceDocument, options);
};
export type PagePlaceComp = React.FC<{data?: Types.PlaceQuery, error?: Apollo.ApolloError}>;
export const withPagePlace = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.PlaceQuery, Types.PlaceQueryVariables>) => (WrappedComponent:PagePlaceComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.PlaceDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrPlace = {
      getServerPage: getServerPagePlace,
      withPage: withPagePlace,
      usePage: usePlace,
    }
export async function getServerPagePlaces
    (options: Omit<Apollo.QueryOptions<Types.PlacesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.PlacesQuery>({ ...options, query: Operations.PlacesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePlaces = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.PlacesQuery, Types.PlacesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.PlacesDocument, options);
};
export type PagePlacesComp = React.FC<{data?: Types.PlacesQuery, error?: Apollo.ApolloError}>;
export const withPagePlaces = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.PlacesQuery, Types.PlacesQueryVariables>) => (WrappedComponent:PagePlacesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.PlacesDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrPlaces = {
      getServerPage: getServerPagePlaces,
      withPage: withPagePlaces,
      usePage: usePlaces,
    }
export async function getServerPageProductBundle
    (options: Omit<Apollo.QueryOptions<Types.ProductBundleQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProductBundleQuery>({ ...options, query: Operations.ProductBundleDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProductBundle = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductBundleQuery, Types.ProductBundleQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ProductBundleDocument, options);
};
export type PageProductBundleComp = React.FC<{data?: Types.ProductBundleQuery, error?: Apollo.ApolloError}>;
export const withPageProductBundle = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductBundleQuery, Types.ProductBundleQueryVariables>) => (WrappedComponent:PageProductBundleComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ProductBundleDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrProductBundle = {
      getServerPage: getServerPageProductBundle,
      withPage: withPageProductBundle,
      usePage: useProductBundle,
    }
export async function getServerPageProductSearchFilter
    (options: Omit<Apollo.QueryOptions<Types.ProductSearchFilterQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProductSearchFilterQuery>({ ...options, query: Operations.ProductSearchFilterDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProductSearchFilter = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductSearchFilterQuery, Types.ProductSearchFilterQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ProductSearchFilterDocument, options);
};
export type PageProductSearchFilterComp = React.FC<{data?: Types.ProductSearchFilterQuery, error?: Apollo.ApolloError}>;
export const withPageProductSearchFilter = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductSearchFilterQuery, Types.ProductSearchFilterQueryVariables>) => (WrappedComponent:PageProductSearchFilterComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ProductSearchFilterDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrProductSearchFilter = {
      getServerPage: getServerPageProductSearchFilter,
      withPage: withPageProductSearchFilter,
      usePage: useProductSearchFilter,
    }
export async function getServerPageProductSearch
    (options: Omit<Apollo.QueryOptions<Types.ProductSearchQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProductSearchQuery>({ ...options, query: Operations.ProductSearchDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProductSearch = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductSearchQuery, Types.ProductSearchQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ProductSearchDocument, options);
};
export type PageProductSearchComp = React.FC<{data?: Types.ProductSearchQuery, error?: Apollo.ApolloError}>;
export const withPageProductSearch = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductSearchQuery, Types.ProductSearchQueryVariables>) => (WrappedComponent:PageProductSearchComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ProductSearchDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrProductSearch = {
      getServerPage: getServerPageProductSearch,
      withPage: withPageProductSearch,
      usePage: useProductSearch,
    }
export async function getServerPageProductTypes
    (options: Omit<Apollo.QueryOptions<Types.ProductTypesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProductTypesQuery>({ ...options, query: Operations.ProductTypesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProductTypes = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductTypesQuery, Types.ProductTypesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ProductTypesDocument, options);
};
export type PageProductTypesComp = React.FC<{data?: Types.ProductTypesQuery, error?: Apollo.ApolloError}>;
export const withPageProductTypes = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductTypesQuery, Types.ProductTypesQueryVariables>) => (WrappedComponent:PageProductTypesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ProductTypesDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrProductTypes = {
      getServerPage: getServerPageProductTypes,
      withPage: withPageProductTypes,
      usePage: useProductTypes,
    }
export async function getServerPageProjects
    (options: Omit<Apollo.QueryOptions<Types.ProjectsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProjectsQuery>({ ...options, query: Operations.ProjectsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProjects = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProjectsQuery, Types.ProjectsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ProjectsDocument, options);
};
export type PageProjectsComp = React.FC<{data?: Types.ProjectsQuery, error?: Apollo.ApolloError}>;
export const withPageProjects = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProjectsQuery, Types.ProjectsQueryVariables>) => (WrappedComponent:PageProjectsComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ProjectsDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrProjects = {
      getServerPage: getServerPageProjects,
      withPage: withPageProjects,
      usePage: useProjects,
    }
export async function getServerPagePropertyDescriptors
    (options: Omit<Apollo.QueryOptions<Types.PropertyDescriptorsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.PropertyDescriptorsQuery>({ ...options, query: Operations.PropertyDescriptorsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePropertyDescriptors = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.PropertyDescriptorsQuery, Types.PropertyDescriptorsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.PropertyDescriptorsDocument, options);
};
export type PagePropertyDescriptorsComp = React.FC<{data?: Types.PropertyDescriptorsQuery, error?: Apollo.ApolloError}>;
export const withPagePropertyDescriptors = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.PropertyDescriptorsQuery, Types.PropertyDescriptorsQueryVariables>) => (WrappedComponent:PagePropertyDescriptorsComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.PropertyDescriptorsDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrPropertyDescriptors = {
      getServerPage: getServerPagePropertyDescriptors,
      withPage: withPagePropertyDescriptors,
      usePage: usePropertyDescriptors,
    }
export async function getServerPageRelatedProducts
    (options: Omit<Apollo.QueryOptions<Types.RelatedProductsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RelatedProductsQuery>({ ...options, query: Operations.RelatedProductsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRelatedProducts = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RelatedProductsQuery, Types.RelatedProductsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.RelatedProductsDocument, options);
};
export type PageRelatedProductsComp = React.FC<{data?: Types.RelatedProductsQuery, error?: Apollo.ApolloError}>;
export const withPageRelatedProducts = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RelatedProductsQuery, Types.RelatedProductsQueryVariables>) => (WrappedComponent:PageRelatedProductsComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.RelatedProductsDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrRelatedProducts = {
      getServerPage: getServerPageRelatedProducts,
      withPage: withPageRelatedProducts,
      usePage: useRelatedProducts,
    }
export async function getServerPageRentalStationById
    (options: Omit<Apollo.QueryOptions<Types.RentalStationByIdQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RentalStationByIdQuery>({ ...options, query: Operations.RentalStationByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRentalStationById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RentalStationByIdQuery, Types.RentalStationByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.RentalStationByIdDocument, options);
};
export type PageRentalStationByIdComp = React.FC<{data?: Types.RentalStationByIdQuery, error?: Apollo.ApolloError}>;
export const withPageRentalStationById = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RentalStationByIdQuery, Types.RentalStationByIdQueryVariables>) => (WrappedComponent:PageRentalStationByIdComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.RentalStationByIdDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrRentalStationById = {
      getServerPage: getServerPageRentalStationById,
      withPage: withPageRentalStationById,
      usePage: useRentalStationById,
    }
export async function getServerPageRentalStations
    (options: Omit<Apollo.QueryOptions<Types.RentalStationsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RentalStationsQuery>({ ...options, query: Operations.RentalStationsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRentalStations = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RentalStationsQuery, Types.RentalStationsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.RentalStationsDocument, options);
};
export type PageRentalStationsComp = React.FC<{data?: Types.RentalStationsQuery, error?: Apollo.ApolloError}>;
export const withPageRentalStations = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RentalStationsQuery, Types.RentalStationsQueryVariables>) => (WrappedComponent:PageRentalStationsComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.RentalStationsDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrRentalStations = {
      getServerPage: getServerPageRentalStations,
      withPage: withPageRentalStations,
      usePage: useRentalStations,
    }
export async function getServerPageSavedPlaces
    (options: Omit<Apollo.QueryOptions<Types.SavedPlacesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SavedPlacesQuery>({ ...options, query: Operations.SavedPlacesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSavedPlaces = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SavedPlacesQuery, Types.SavedPlacesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.SavedPlacesDocument, options);
};
export type PageSavedPlacesComp = React.FC<{data?: Types.SavedPlacesQuery, error?: Apollo.ApolloError}>;
export const withPageSavedPlaces = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SavedPlacesQuery, Types.SavedPlacesQueryVariables>) => (WrappedComponent:PageSavedPlacesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.SavedPlacesDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrSavedPlaces = {
      getServerPage: getServerPageSavedPlaces,
      withPage: withPageSavedPlaces,
      usePage: useSavedPlaces,
    }
export async function getServerPageServiceInfo
    (options: Omit<Apollo.QueryOptions<Types.ServiceInfoQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ServiceInfoQuery>({ ...options, query: Operations.ServiceInfoDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useServiceInfo = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ServiceInfoQuery, Types.ServiceInfoQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ServiceInfoDocument, options);
};
export type PageServiceInfoComp = React.FC<{data?: Types.ServiceInfoQuery, error?: Apollo.ApolloError}>;
export const withPageServiceInfo = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ServiceInfoQuery, Types.ServiceInfoQueryVariables>) => (WrappedComponent:PageServiceInfoComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ServiceInfoDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrServiceInfo = {
      getServerPage: getServerPageServiceInfo,
      withPage: withPageServiceInfo,
      usePage: useServiceInfo,
    }
export async function getServerPageRecommendedProductsSlice
    (options: Omit<Apollo.QueryOptions<Types.RecommendedProductsSliceQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RecommendedProductsSliceQuery>({ ...options, query: Operations.RecommendedProductsSliceDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRecommendedProductsSlice = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RecommendedProductsSliceQuery, Types.RecommendedProductsSliceQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.RecommendedProductsSliceDocument, options);
};
export type PageRecommendedProductsSliceComp = React.FC<{data?: Types.RecommendedProductsSliceQuery, error?: Apollo.ApolloError}>;
export const withPageRecommendedProductsSlice = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RecommendedProductsSliceQuery, Types.RecommendedProductsSliceQueryVariables>) => (WrappedComponent:PageRecommendedProductsSliceComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.RecommendedProductsSliceDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrRecommendedProductsSlice = {
      getServerPage: getServerPageRecommendedProductsSlice,
      withPage: withPageRecommendedProductsSlice,
      usePage: useRecommendedProductsSlice,
    }
export async function getServerPageRentalStationsSlice
    (options: Omit<Apollo.QueryOptions<Types.RentalStationsSliceQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RentalStationsSliceQuery>({ ...options, query: Operations.RentalStationsSliceDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRentalStationsSlice = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RentalStationsSliceQuery, Types.RentalStationsSliceQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.RentalStationsSliceDocument, options);
};
export type PageRentalStationsSliceComp = React.FC<{data?: Types.RentalStationsSliceQuery, error?: Apollo.ApolloError}>;
export const withPageRentalStationsSlice = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RentalStationsSliceQuery, Types.RentalStationsSliceQueryVariables>) => (WrappedComponent:PageRentalStationsSliceComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.RentalStationsSliceDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrRentalStationsSlice = {
      getServerPage: getServerPageRentalStationsSlice,
      withPage: withPageRentalStationsSlice,
      usePage: useRentalStationsSlice,
    }
export async function getServerPageTenantByKey
    (options: Omit<Apollo.QueryOptions<Types.TenantByKeyQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.TenantByKeyQuery>({ ...options, query: Operations.TenantByKeyDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTenantByKey = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantByKeyQuery, Types.TenantByKeyQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.TenantByKeyDocument, options);
};
export type PageTenantByKeyComp = React.FC<{data?: Types.TenantByKeyQuery, error?: Apollo.ApolloError}>;
export const withPageTenantByKey = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantByKeyQuery, Types.TenantByKeyQueryVariables>) => (WrappedComponent:PageTenantByKeyComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.TenantByKeyDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrTenantByKey = {
      getServerPage: getServerPageTenantByKey,
      withPage: withPageTenantByKey,
      usePage: useTenantByKey,
    }
export async function getServerPageTenants
    (options: Omit<Apollo.QueryOptions<Types.TenantsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.TenantsQuery>({ ...options, query: Operations.TenantsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTenants = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantsQuery, Types.TenantsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.TenantsDocument, options);
};
export type PageTenantsComp = React.FC<{data?: Types.TenantsQuery, error?: Apollo.ApolloError}>;
export const withPageTenants = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantsQuery, Types.TenantsQueryVariables>) => (WrappedComponent:PageTenantsComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.TenantsDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrTenants = {
      getServerPage: getServerPageTenants,
      withPage: withPageTenants,
      usePage: useTenants,
    }
export async function getServerPageValidateAddresses
    (options: Omit<Apollo.QueryOptions<Types.ValidateAddressesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ValidateAddressesQuery>({ ...options, query: Operations.ValidateAddressesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useValidateAddresses = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ValidateAddressesQuery, Types.ValidateAddressesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ValidateAddressesDocument, options);
};
export type PageValidateAddressesComp = React.FC<{data?: Types.ValidateAddressesQuery, error?: Apollo.ApolloError}>;
export const withPageValidateAddresses = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ValidateAddressesQuery, Types.ValidateAddressesQueryVariables>) => (WrappedComponent:PageValidateAddressesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.ValidateAddressesDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrValidateAddresses = {
      getServerPage: getServerPageValidateAddresses,
      withPage: withPageValidateAddresses,
      usePage: useValidateAddresses,
    }