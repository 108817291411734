import {
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import merge from 'deepmerge';

import { isBrowser, isProduction } from '../environment';
import { getInMemoryCache } from './get-apollo-cache';
import { getApolloLink } from './get-apollo-link';
import isEqual from 'lodash/isEqual';

interface IWithApolloContext {
  initialState?: NormalizedCacheObject;
  session: SessionType;
}

let apolloClientInstance: ApolloClient<NormalizedCacheObject> | null = null;

export const getApolloClient = (
  { initialState = {}, session }: IWithApolloContext = {session: null},
): ApolloClient<NormalizedCacheObject> => {
  if (null !== apolloClientInstance) {
    const existingCache = apolloClientInstance.cache.extract();

    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });
    apolloClientInstance.cache.restore(data);

    return apolloClientInstance;
  }

  /**
   * Setup cache for GraphQL requests
   */
  const cache = getInMemoryCache();
  cache.restore(initialState);

  const _apolloClientInstance = new ApolloClient({
    connectToDevTools: false === isProduction(),
    ssrMode: !isBrowser(),
    link: getApolloLink(session),
    cache,
  });

  // Store apollo client global on browser.
  if (true === isBrowser()) {
    apolloClientInstance = _apolloClientInstance;
  }

  return _apolloClientInstance;
};
