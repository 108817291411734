import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { DEFAULT_TIMEZONE, SERVER_TIMEZONE } from '../constants/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const readableServerTime = (utcDate: string): string => {
  return dayjs.tz(utcDate, SERVER_TIMEZONE).tz(DEFAULT_TIMEZONE).format('HH:mm');
};
