import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { ssrCart } from '../@types/codegen/page';
import {
  AddOrUpdateCartItemDocument,
  AddOrUpdateCartItemMutation,
  ApplyCouponDocument,
  ApplyCouponMutation,
  BookCartDocument,
  BookCartMutation,
  BookCartMutationVariables,
  CartDetailsFragment,
  CartItemInput,
  MutationAddOrUpdateCartItemArgs,
  MutationApplyCouponArgs,
  MutationRemoveCartItemArgs,
  RemoveCartItemDocument,
  RemoveCartItemMutation,
} from '../@types/codegen/graphql';
import { ApolloError, useMutation } from '@apollo/client';
import { meUserState } from './recoil/auth.store';
import { useRecoilValue } from 'recoil';

interface ICartContextProps {
  cart: CartDetailsFragment | null;
  billingAddressId: string;
  loading: boolean;
  addOrUpdateBasketItemMutation: (props: CartItemInput) => Promise<AddOrUpdateCartItemMutation | null | undefined>;
  removeCartItemError: ApolloError | undefined;
  bookCartNow: (billingAddressId: string | null) => Promise<BookCartMutation | null | undefined>;
  addCoupon: (code: string) => Promise<ApplyCouponMutation | null | undefined>;
  removeCartItem: (itemId: string) => Promise<RemoveCartItemMutation | null | undefined>;
  refetchCart: () => Promise<void>;
  updateCartData: (cart: CartDetailsFragment | null) => void;
  setBillingAddressId: (addressId: string) => void;
}

const CartContext = createContext<ICartContextProps>({
  cart: null,
  loading: false,
  billingAddressId: 'default',
  removeCartItemError: undefined,
  addOrUpdateBasketItemMutation: async () => {
    // eslint-disable-next-line no-console
    console.error('Cart Context not ready yet.');

    return null;
  },
  bookCartNow: async () => {
    // eslint-disable-next-line no-console
    console.error('Cart Context not ready yet.');

    return null;
  },
  addCoupon: async () => {
    // eslint-disable-next-line no-console
    console.error('Cart Context not ready yet.');

    return null;
  },
  removeCartItem: async () => {
    // eslint-disable-next-line no-console
    console.error('Cart Context not ready yet.');

    return null;
  },
  refetchCart: async () => {
    // eslint-disable-next-line no-console
    console.error('Cart Context not ready yet.');

    return;
  },
  updateCartData: async () => {
    // eslint-disable-next-line no-console
    console.error('Cart Context not ready yet.');

    return;
  },
  setBillingAddressId: async () => {
    // eslint-disable-next-line no-console
    console.error('Cart Context not ready yet.');

    return null;
  },
});

interface ICartWrapperprops {
  children: React.ReactNode;
}

export const CartWrapper: FC<ICartWrapperprops> = (props) => {
  const { children } = props;
  const [billingAddressId, setBillingAddressId] = useState<string>('default');

  const { data, loading, refetch } = ssrCart.usePage(() => ({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
  }));

  const [cart, setCart] = useState<CartDetailsFragment | null>(data?.cart ?? null);
  const [bookCart] = useMutation<BookCartMutation, BookCartMutationVariables>(BookCartDocument);
  const [addOrUpdateBasketItemutation] = useMutation<AddOrUpdateCartItemMutation, MutationAddOrUpdateCartItemArgs>(AddOrUpdateCartItemDocument);
  const [applyCoupon] = useMutation<ApplyCouponMutation, MutationApplyCouponArgs>(ApplyCouponDocument);
  const [removeItem, { error: removeCartItemError }] = useMutation<RemoveCartItemMutation, MutationRemoveCartItemArgs>(RemoveCartItemDocument);

  const meUser = useRecoilValue(meUserState);

  /**
   * Update cart data when the user logged in or out.
   */
  useEffect(() => {
    if (!meUser) {
      setCart(null);
    } else {
      refetchCart();
    }
  }, [meUser]);

  /**
   * Refetch cart data.
   */
  const refetchCart = useCallback(async () => {
    refetch();
  }, []);

  /**
   * Update cart data when a refetch has been finished
   */
  useEffect(() => {
    if (data) {
      updateCartData(data?.cart ?? null);
    }
  }, [data]);

  /**
   * Refetch cart data when the cart id changes.
   */
  useEffect(() => {
    refetchCart();
  }, []);

  /**
   * Update cart data and set the cart id in the cookie.
   * If you want to remove the cart from client side, set the cart parameter to null.
   */
  const updateCartData = (cart: CartDetailsFragment | null) => {
    setCart(cart ?? null);
  };

  const addOrUpdateBasketItem = useCallback(async (item: CartItemInput): Promise<AddOrUpdateCartItemMutation | null | undefined> => {
    const res = await addOrUpdateBasketItemutation({
      variables: {
        input: {
          item,
        },
      },
    });

    updateCartData(res.data?.addOrUpdateCartItem ?? null);

    return res.data;
  }, []);



  /**
   * Add a coupon to the cart.
   */
  const addCoupon = async (code: string): Promise<ApplyCouponMutation | null | undefined> => {
    const res = await applyCoupon({
      variables: {
        input: {
          couponCode: code,
        },
      },
    });

    updateCartData(res.data?.applyCoupon ?? null);

    return res.data;
  };

  const bookCartNow = async (
    billingAddressId: string | null,
  ): Promise<BookCartMutation | null | undefined> => {
    const res = await bookCart({
      variables: {
        input: {
          billingAddressId: billingAddressId,
        },
      },
    });

    return res.data;
  };

  /**
   * Remove a cart item from the cart.
   */
  const removeCartItem = async (itemId: string): Promise<RemoveCartItemMutation | null | undefined> => {
    const res = await removeItem({
      variables: {
        input: {
          itemId: itemId,
        },
      },
    });

    updateCartData(res.data?.removeCartItem ?? null);

    return res.data;
  };

  return (
    <CartContext.Provider value={{
      cart,
      loading,
      billingAddressId,
      removeCartItemError,
      setBillingAddressId,
      addOrUpdateBasketItemMutation: addOrUpdateBasketItem,
      bookCartNow,
      addCoupon,
      removeCartItem,
      refetchCart,
      updateCartData,
    }}>
      {children}
    </CartContext.Provider>
  );
};

export function useCartContext(): ICartContextProps {
  return useContext(CartContext);
}
