import { useMemo } from 'react';
import dayjs from 'dayjs';
import { LastOrderYearDate } from '../constants/checkout';

const isNonWorkingDay = (day: dayjs.Dayjs): boolean => {
  return 0 === day.day() || 6 === day.day();
};

export interface IUseDateInfoResponse {
  nonWorkingDays: {
    dates: Date[];
  };
}

export const generateWeekends = (): string[] => {
  const dates: string[] = [];
  let currentDate = dayjs();
  const endDate = dayjs(LastOrderYearDate);

  while (currentDate <= endDate) {
    if (isNonWorkingDay(currentDate)) {
      dates.push(currentDate.format('YYYY-MM-DD'));
    }

    currentDate = currentDate.add(1, 'day');
  }

  return dates;
};

export const useDateInfo = (): IUseDateInfoResponse => {
  const weekends = useMemo(() => generateWeekends().map(dateString => dayjs(dateString).toDate()), []);

  return {
    nonWorkingDays: {
      dates: weekends,
    },
  };
};
